import { RestrictionType } from "@iabtechlabtcf/core";
function isPurposeEnabledForVendor(model, id, isLegInt, param) {
    let { id: vendorId, legIntPurposes } = param;
    var _publisherRestrictions_filter_;
    const publisherRestrictions = model.publisherRestrictions.getRestrictions(vendorId);
    const disabledPurposes = publisherRestrictions.map((param)=>{
        let { purposeId, restrictionType } = param;
        return purposeId === id && restrictionType === RestrictionType.NOT_ALLOWED && purposeId;
    }).filter(Boolean);
    if (disabledPurposes.indexOf(id) > -1) {
        return false;
    }
    let legIntRestriction = (_publisherRestrictions_filter_ = publisherRestrictions.filter((param)=>{
        let { purposeId, restrictionType } = param;
        return purposeId === id && restrictionType !== RestrictionType.NOT_ALLOWED;
    })[0]) == null ? void 0 : _publisherRestrictions_filter_.restrictionType;
    // Fallback to default setting
    if (!legIntRestriction) {
        legIntRestriction = legIntPurposes.indexOf(id) > -1 ? RestrictionType.REQUIRE_LI : RestrictionType.REQUIRE_CONSENT;
    }
    if (isLegInt && legIntRestriction === RestrictionType.REQUIRE_CONSENT) {
        return false;
    } else if (!isLegInt && legIntRestriction === RestrictionType.REQUIRE_LI) {
        return false;
    }
    return true;
}
export { isPurposeEnabledForVendor };
